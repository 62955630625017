<script setup lang="ts">
import { capitalize, toLower, debounce } from 'lodash'
// Types.
//
import type { BaseValidation } from '@vuelidate/core'
// Properties and events.
//
const emit = defineEmits([
  'update:modelValue',
  'input:blur',
  'process:input-button',
  'input:focus',
])
export interface Props {
  delay?: number
  label: string
  type: string
  readonly?: boolean
  styleOpts?: string
  floatLabel?: string
  btnStyle?: string
  btnIcon?: string
  btnIconSize?: string
  btnIconStyle?: string
  inputButton?: boolean
  inputButtonIcon?: string
  removeSpacing?: boolean
  fullWidth?: boolean
  highlight?: boolean
  modelValue: string | any
  /**
   * Vuelidate Rules
   */
  eachValidation?: boolean
  validation?: BaseValidation
  validateOnFocus?: boolean
}
const props = defineProps<Props>()
// Default state.
//
const id = computed(() => toLower(props.label))
const fullWidth = toRef(props, 'fullWidth')
const highlight = toRef(props, 'highlight')
const onFocus = ref(false)
const hasSpacing = props.removeSpacing ? ref(false) : ref(true)
const setDelayed = props.delay ? ref(props.delay) : ref(0)
const isReadonly = props.readonly ? ref(true) : ref(false)
const isFloated = capitalize(props.floatLabel) === 'No' ? ref(false) : ref(true)
const validateOnFocus = toRef(props, 'validateOnFocus')
const invalid = computed(
  () =>
    props.validation && props.validation.$dirty && props.validation.$invalid,
)
const isRequired = computed(
  () =>
    (props.validation && props.validation.required) ||
    (props.validation &&
      props.validation.requiredIf &&
      props.validation.requiredIf.$params.prop),
)
const isPending = computed(() => props.validation && props.validation.$pending)
// Model.
//
const value = computed({
  get(): string | any {
    return props.modelValue
  },

  set: debounce(function (value) {
    emit('update:modelValue', value)
  }, setDelayed.value),
})
// Functions.
//
const clearInput = function clearInput() {
  emit('update:modelValue', '')
}
const processValidation = function processValidation() {
  if (props.validation && !props.eachValidation) {
    props.validation.$touch()
  } else {
    emit('input:blur', value.value)
  }
}
const processInputButton = function processInputButton() {
  emit('process:input-button')
}
const processFocusedValidation = function processFocusedValidation() {
  if (!validateOnFocus.value) return

  if (props.validation && !props.eachValidation) {
    props.validation.$touch()
  } else {
    emit('input:focus', value.value)
  }
}
</script>

<template>
  <div
    class="rk-form__group"
    :class="{
      'has-error': invalid,
      'has-pending': isPending,
      'mb-3': hasSpacing,
      'pb-1': !hasSpacing,
      'w-100': fullWidth,
    }"
  >
    <div
      class="position-relative"
      :class="{
        'form-floating': isFloated,
      }"
    >
      <input
        v-model="value"
        :id="`${id}-id`"
        :type="type"
        :readonly="isReadonly"
        :placeholder="label"
        :name="id"
        class="rk-input form-control"
        :class="[
          styleOpts,
          {
            'is-invalid': invalid,
            'rk-input--readonly': readonly,
            'rk-input--rounded': inputButton,
            'rk-highlight': highlight && !onFocus,
          },
        ]"
        :required="isRequired"
        @blur="processValidation"
        @focus="processFocusedValidation"
        @focusin="onFocus = true"
        @focusout="onFocus = false"
      />
      <label
        v-if="isFloated"
        :for="`${id}-id`"
        :class="{
          readonly: readonly,
          'rk-highlight__label': highlight && !onFocus,
        }"
      >
        {{ label }}<i v-if="isRequired">*</i>
      </label>
      <Btn
        v-show="value && value.length > 0 && !readonly"
        :base-style="btnStyle"
        style-opts="rk-btn--clear-input"
        :class="{
          'top-0 mt-1': !isFloated,
          search: styleOpts?.includes('search'),
        }"
        tabindex="-1"
        @process:click="clearInput"
      >
        <i
          class="rk-icon fontello"
          :class="[
            btnIcon || 'icon-sys-close',
            btnIconStyle || 'rk-icon--default',
            btnIconSize || 'rk-icon--text-xxs',
          ]"
        ></i>
      </Btn>
    </div>
    <Btn
      v-if="inputButton"
      style-opts="rk-btn--input"
      @process:click="processInputButton"
    >
      <i
        class="rk-icon rk-icon--primary fontello"
        :class="[inputButtonIcon || 'icon-sys-search']"
      ></i>
    </Btn>
    <div
      v-if="validation && invalid && !eachValidation"
      class="rk-form__error pt-3"
    >
      <p v-for="error in validation.$errors" :key="error.$uid" class="m-0">
        {{ error.$message }}
      </p>
    </div>
  </div>
</template>

<style lang="scss"></style>
